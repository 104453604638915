<template>
  <VaadeGuard :routeName="RouteName.TULUD">
    <VaadeHeader :selected-date="parsedData?.selectedDate" />
    <div
      class="w-full xl:grid xl:grid-cols-2 gap-4 space-y-6 xl:space-y-0 px-6 pt-3 pb-6"
    >
      <BaseTable
        :data="parsedData?.data"
        :display-footer="true"
        footer-name="TULUD"
        :expanded-code="expandedCode"
        @onExpand="onExpand"
      />

      <VaadeCharts
        :andmik="parsedData"
        @onExpand="onExpand"
        :expanded-code="expandedCode"
        title="tulud"
        progressTitle="Eelarve täitmine"
        progressSubTitle="Täitmise %"
      />
    </div>
  </VaadeGuard>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, ref } from "vue";
import BaseTable from "@/components/table/BaseTable.vue";
import { EelarveAndmik } from "@/firebase/parse/types";
import VaadeCharts from "@/components/common/VaadeCharts.vue";
import { useMunicipalityStore } from "@/store/MunicipalityStore";

import VaadeHeader from "@/components/common/vaadeheader/VaadeHeader.vue";
import { RouteName } from "@/router/types";
import VaadeGuard from "@/components/common/VaadeGuard.vue";
export default defineComponent({
  components: {
    VaadeGuard,
    VaadeHeader,
    VaadeCharts,
    BaseTable,
  },
  setup() {
    const municipalityStore = useMunicipalityStore();
    const municipalityState = municipalityStore.getState();
    const parsedData = computed<DeepReadonly<EelarveAndmik> | null>(
      () => municipalityState.tulud
    );
    const expandedCode = ref<string | undefined>(undefined);
    const onExpand = (code: string | undefined) =>
      (expandedCode.value = expandedCode.value === code ? undefined : code);
    return {
      parsedData,
      onExpand,
      expandedCode,
      RouteName,
    };
  },
});
</script>
